<template>
  <!-- Footer -->
  <footer class="-rn-footer" id="footer">
    <div class="rn-footer-wrapper footer">
      <v-container fluid>
        <v-row>
          <v-col lg="12">
            <div class="footer-top">
              <div class="content">
                <span>更多SigmaAI实操课程及讲座筹备中，有意者可联系下方电话!</span>
                <h4>联系我们</h4>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="footer-wrapper ptb--100">
        <v-container fluid>
          <v-row>
            <!-- Start Single Wedget -->
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">公司信息</h3>
                <div class="content">
                  <p>
                    希革马（宁波市）人工智能 <br />
                    有限责任公司
                  </p>
                </div>
              </div>
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">办公地址</h3>
                <div class="content">
                  <p>浙江省宁波市鄞州区潘火路 <br />215号605-2室</p>
                </div>
              </div>
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">联系方式</h3>
                <div class="content">
                  <div class="address">
                    <p>
                      <a href="mailto:3226700793@qq.com">邮箱：3226700793@qq.com</a>
                    </p>
                    <p><a href="tel:15258355440">联系号码:+86 15258355440</a></p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">媒体链接</h3>
                <div class="content">
                  <ul class="quick-link">
                    <li v-for="(link, i) in  quickLink " :key="i">
                      <a :href="link.RouteName" target="_blank">{{ link.name }}</a>
                      <!-- <router-link :to="link.RouteName">{{
                      link.name
                    }}</router-link> -->
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <FooterTwo />
  </footer>
  <!--// Footer -->
</template>

<script>
import FooterTwo from "./FooterTwo";
export default {
  components: {
    FooterTwo,
  },
  data() {
    return {
      quickLink: [
        {
          name: "抖音",
          RouteName: "https://www.douyin.com/user/MS4wLjABAAAAsO8_pVvouqhZs_c3XXrxx5MRpGLTwf6PHW6nLhmJEMc",
        },
        {
          name: "小红书",
          RouteName: "https://www.xiaohongshu.com/user/profile/631ccec10000000023027feb?xhsshare=CopyLink&appuid=631ccec10000000023027feb&apptime=1715909131",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
