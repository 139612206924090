<template>
  <div class="blog-details-page">
    <HeaderFive>
      <img slot="logo" src="../assets/img/xgm/icon_text.png" />
    </HeaderFive>

    <!-- Start Bradcaump area -->
    <div class="bradcaump_area">
      <div class="parallaxContainer" data-black-overlay="6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center bradcaump_inner">
                <h2 class="bradcaump-title">
                  {{ blogData.title }}
                </h2>
                <ul class="blog-meta">
                  <li>{{ blogMeta.date }}</li>
                  <li :class="blogMeta.className">-</li>
                  <li>
                    By : {{ blogMeta.author }}
                    <!-- <a :href="blogMeta.authorUrl" target="_blank">
                      {{ blogMeta.author }}</a> -->
                  </li>
                  <!-- <li :class="blogMeta.className">-</li> -->
                  <!-- <li>{{ blogMeta.comments }}</li> -->
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- End Bradcaump area -->

    <!-- Start Blog Details Area -->
    <div class="rf-blog-details section-ptb-xl">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="text-left blog-details-wrapper">
              <div class="dropcaps-inner">
                <p>
                  {{ blogData.smartTitle }}
                </p>
              </div>
              <div class="bl-feature">
                <ul class="feature-blog-list d-flex">
                  <li>
                    <v-card>
                      <img :src="blogData.mainImgUrl" alt="personal portfolio" class="max-size-image" />
                    </v-card>
                  </li>
                </ul>
              </div>
              <div class="content">
                <p v-for="item, index in p_list" :key="index">
                  {{ item }}
                </p>
                <!-- <blockquote>
                  <p>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet,looked up one of the more obscure Latin words, consectetur,
                    from a Lorem Ipsum passage looked up one of the more obscure Latin words
                  </p>
                </blockquote> -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area -->

    <Footer />
  </div>
</template>

<script>
import HeaderFive from "../components/header/HeaderFive";
import Footer from "../components/footer/Footer";
import api from "../api/api";
export default {
  components: {
    HeaderFive,
    Footer,
  },
  data() {
    return {
      blogMeta: {
        date: "April 1, 2021",
        className: "separator",
        author: "希革马",
        authorUrl: "#",
        comments: "07 Comments",
      },
      blogListImage: {
        img1: require("../assets/img/news/md-img-1.jpg"),
        img2: require("../assets/img/news/md-img-2.jpg"),
      },
      blogData: {
        title: ''
      },
      p_list: []
    };
  },

  mounted() {
    const currentUrl = this.$route.fullPath;
    const list = currentUrl.split('=');
    const id = list[list.length - 1];
    console.log(id);
    this.getContent(id);
  },
  methods: {
    getContent(id) {
      api.get('/center/article?id=' + id).then(response => {
        const data = response.data;
        if (data.code == 200) {
          if (data.data == null) {
            this.$router.push('*');
          }
          this.blogData = data.data;
          console.log(this.blogData);
          const time = this.convertToYYYYMMDDHHmmss(this.blogData.createTime);
          this.blogMeta.date = time;
          this.p_list = this.blogData.mainText.split('\n');
          console.log(this.p_list);
        }
      }).catch(error => {
        console.log(error);
        this.$router.push('*');
      });
    },
    convertToYYYYMMDDHHmmss(dateString) {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date string');
      }
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}年${month}月${day}日${hours}:${minutes}:${seconds}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.blog-details-page .-rn-footer {
  background: #f9f9f9;
}

.parallaxContainer {
  background: url("../assets/img/xgm/user_banner.png");
  background-size: cover;
}

.d-flex {
  justify-content: center
}

.max-size-image {
  max-width: 500px;
  /* 设置最大宽度 */
  max-height: 400px;
  /* 设置最大高度 */
  width: auto;
  /* 保持原始纵横比 */
  height: auto;
  /* 保持原始纵横比 */
}
</style>
